@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

body {
  font-family: "Poppins", sans-serif;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #e4b87f #0000;
  animation: l16 1s infinite linear;
}

@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}

.loader::before {
  border-color: #f03355 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.loader::after {
  margin: 8px;
}

.dot {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  display: inline-block;
}

.red {
  background-color: rgb(250, 36, 36);
}

.green {
  background-color: rgb(31, 197, 31);
}

.gray {
  background-color: gray;
}


/* Style the scrollbar track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 4px;
}

/* Style the scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 4px;
}

.ag-theme-alpine .ag-row-hover {

  background-color: #fbda7a;
  color: #000;
  font-weight: 600;
}

.hidden-button {
  transition: 0.3s ease-in-out;
}

.splide__arrow {
  top: 39% !important;
}

.splide__arrow--next {
  top: 39% !important;
  right: 25px !important;
}
.arr-btn .splide__arrow--next {
  top: 50% !important;
right: 35px !important;
}
.arr-btn .splide__arrow--prev{
  top: 50% !important;
  left: 25px;
}
.arr-bttn .splide__arrow--next {
  top: 50% !important;

}
.arr-bttn .splide__arrow--prev{
  top: 50% !important;
  left: 25px;
}

